import PropTypes from "prop-types"
import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const Footer = ({ siteTitle, classes }) => (
  <div>
    <Card className={classes.contact}>
      <CardMedia
        className={classes.contactMedia}
      />
      <div className={classes.overlay}>
        <div className={[classes.overlaySlogen, classes.overlaySlogenTop].join(' ')}>
          <h1 className={classes.overlayTitle}>FLY ACADEMIA</h1>
        </div>
        <div className={[classes.overlaySlogen, classes.overlaySlogenMiddle].join(' ')}>
          <h1 className={classes.overlaySlogenMiddleTitle}>最学术的留学咨询服务机构</h1>
        </div>
        <div className={[classes.overlaySlogen, classes.overlaySlogenBottom].join(' ')}>
          <h1 className={classes.overlaySlogenBottomTitle}>Lead to world-class education with excellent academia expertise</h1>
        </div>
      </div>
    </Card>
    <Card className={classes.footer}>
      <CardContent className={classes.footerContent}>
        <Typography className={classes.footerText} component="p">
          {`©${new Date().getFullYear()} FLY ACADEMIA`}
        </Typography>
        <Typography className={classes.pos} component="p">
        </Typography>
      </CardContent>
    </Card>
  </div>
)

const styles = theme => ({
  root: {
  },
  contact: {
    maxWidth: '100%',
    position: 'relative',
    borderRadius: '0px',
  },
  contactMedia: {
    height: '300px',
    backgroundColor: 'rgb(40, 44, 52)',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  overlaySlogen: {
    display: 'flex',
  },
  overlaySlogenTop: {
    color: 'rgb(97, 218, 251)',
  },
  overlaySlogenMiddle: {
    color: '#EEEEEE',
  },
  overlaySlogenMiddleTitle: {
    fontSize: '2.0em',
  },
  overlaySlogenBottom: {
    color: 'rgb(97, 218, 251)',
  },
  overlaySlogenBottomTitle: {
    fontSize: '1.2em',
  },
  overlayIcon: {
    color: 'white',
    fontSize: '1.6em',
    marginRight: '24px',
  },
  footer: {
    maxWidth: '100%',
    position: 'relative',
    borderRadius: '0px',
  },
  footerContent: {
    width: '100%',
    minHeight: 24,
    backgroundColor: '#20232a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:last-child': {
      paddingBottom: 12,
    }
  },
  footerText: {
    color: '#DDDDDD',
  },
});


Footer.propTypes = {
  siteTitle: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default withStyles(styles)(Footer);
